<template>
  <div>
    <h3>Статусы</h3>
    <v-divider></v-divider> <br />
    <v-btn color="success" @click="addRow" small> <v-icon dark>fa-plus</v-icon>{{ $t('all.add') }} </v-btn>

    <v-simple-table dense>
      <thead>
        <tr>
          <th class="text-left">{{ $t('status.name') }}</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="grp in tableGroup">
          <tr v-bind:key="grp.id">
            <th colspan="2" v-bind:style="{ backgroundColor: '#' + grp.color }">
              {{ $t(grp.name) }}
            </th>
          </tr>
          <tr v-for="item in filterItems(grp.id)" :key="item.id" @click="editRow(item)">
            <td>{{ item.name }}</td>
            <td>{{ item.rules }}</td>
          </tr>
        </template>
      </tbody>
    </v-simple-table>

    <modal_edit
      v-if="modal"
      v-bind:title="modalTitle"
      v-bind:view="modalView"
      v-on:close="modalClose"
      v-on:delete="deleteRow"
    ></modal_edit>
    <modal_delete v-if="modal_delete" v-bind:view="modalView" v-on:close="modalDelClose"></modal_delete>
  </div>
</template>

<script>
import modal_edit from './status_edit.vue'
import modal_delete from './status_delete.vue'

export default {
  data() {
    return {
      columns: [
        { name: 'name', title: 'Название' },
        { name: 'resource_count', title: 'Ресурсов', width: '50px' },
      ],
      tableLoad: false,
      tableData: ['load'],
      tableGroup: [],
      modal: '',
      modal_delete: '',
      modalTitle: '',
      modalView: '',
    }
  },
  computed: {},
  components: {
    modal_edit,
    modal_delete,
  },
  mounted: function() {
    this.dataLoad()
  },
  methods: {
    filterItems: function(grp) {
      return this.tableData.filter(f => f.group == grp)
    },

    modalClose: function(prm) {
      this.modal = ''
      this.modal_delete = ''
      if (prm == 'reload') {
        this.dataLoad()
      }
    },
    modalDelClose: function(prm) {
      this.modal_delete = ''
      if (prm == 'reload') {
        this.modal = ''
        this.dataLoad()
      }
    },
    dataLoad: function() {
      var t = this
      this.$http.post(this.$store.state.apiUrl + 'status/load').then(
        response => {
          if (response.body.err > 0) {
            t.tableData = ['error']
          } else {
            t.tableData = response.body.status
            t.tableGroup = response.body.group
          }
          console.log(t.tableGroup)
        },
        err => {
          console.log(err)
        },
      )
    },
    addRow: function() {
      this.modal = true
      this.modalTitle = 'Добавление статуса'
      this.modalView = {}
      //                        this.showModal = true;
      //                        this.modalContent = this.tpl_edit;
      //                      $('#'+this.tag).modal('show')
    },
    editRow: function(view) {
      this.modal = true
      this.modalTitle = 'Редактирование статуса'
      this.modalView = view
    },
    deleteRow: function(view) {
      this.modal_delete = true
      this.modalView = view
    },
  },
}
</script>

<style></style>
