<template>
  <v-dialog v-model="dialog" persistent>
    <v-card>
      <v-card-title class="headline" v-if="view.id">
        {{ $t('status.edittitle') }}
      </v-card-title>
      <v-card-title class="headline" v-if="!view.id">
        {{ $t('status.addtitle') }}
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-text-field
            v-model="view.name"
            :label="$t('status.name')"
            required
            :rules="[(v) => !!v || $t('all.fieldnoempty')]"
          ></v-text-field>
          <v-select
            v-bind:items="
              groups.map((e) => (e = { name: $t(e.name), id: e.id }))
            "
            v-model="groupSelected"
            :label="$t('status.group')"
            bottom
            item-text="name"
            item-value="id"
          >
          </v-select>
          <v-card>
            <v-card-text>
              <p>{{ $t('status.rule') }}</p>
              <v-switch
                v-for="item in rules"
                v-bind:key="item.id"
                v-model="item.checked"
                v-bind:label="item.name"
                color="primary"
                hide-details
              ></v-switch>
            </v-card-text>
          </v-card>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="error" v-if="btnDel" @click.native="del">
          {{ $t('all.delete') }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn @click.native="$emit('close')"> {{ $t('all.close') }} </v-btn>
        <v-btn color="primary" dark @click.native="save">
          {{ $t('all.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import confirm from './../../components/confirm.vue'
import { create } from 'vue-modal-dialogs'
const confirmBox = create(confirm)
export default {
  props: ['title', 'view'],
  data() {
    return {
      dialog: true,
      rules: [],
      groups: [],
      errName: false,
      errRule: false,
      btnDel: false,
      groupSelected: this.view.group ? this.view.group : 1,
    }
  },
  computed: {},
  mounted: function () {
    var t = this,
      status = this.view.id ? this.view.id : ''
    this.btnDel = this.view.id ? true : false
    this.$http.post(this.$store.state.apiUrl + 'status/rule/' + status).then(
      (response) => {
        t.rules = response.body
      },
      (err) => {
        console.log(err)
      }
    )
    this.$http.post(this.$store.state.apiUrl + 'status/group/' + status).then(
      (response) => {
        t.groups = response.body
      },
      (err) => {
        console.log(err)
      }
    )
  },
  methods: {
    del: function () {
      var t = this
      confirmBox({
        text: t.$t('status.delconfirm', [t.view.name]),
        type: 'delete',
        title: t.$t('status.titledel'),
      })
        .transition()
        .then((response) => {
          if (response) {
            this.$http
              .post(this.$store.state.apiUrl + 'status/delete/', {
                id: t.view.id,
              })
              .then(
                (response) => {
                  t.$store.dispatch('setMessage', {
                    type: response.body.err == 1 ? 'error' : 'success',
                    message: t.$t(response.body.msg),
                  })
                  if (!response.body.err) {
                    t.$emit('close', 'reload')
                  }
                },
                (err) => {
                  console.log(err)
                }
              )
          }
        })
    },
    save: function () {
      var i = 0,
        flg_check = true,
        prmRes = [],
        t = this,
        arr = Object.assign({}, this.view)

      if (!this.view.name) {
        this.errName = t.$t('all.fieldnoempty')
        flg_check = false
      } else {
        this.errName = false
      }
      console.log(this.groupSelected)
      console.log(this.groups)
      for (var res in this.rules) {
        //                console.log(this.resources[res].checked);
        if (this.rules[res].checked == true) {
          prmRes[i] = this.rules[res].id
          i++
        }
      }
      if (i == 0) {
        this.errRule = t.$t('status.db.norule')
        flg_check = false
      } else {
        this.errRes = false
      }
      if (flg_check) {
        this.$http
          .post(this.$store.state.apiUrl + 'status/save/', {
            status: arr,
            rule: prmRes,
            group: this.groupSelected,
          })
          .then(
            (response) => {
              t.$store.dispatch('setMessage', {
                type: response.body.err == 1 ? 'error' : 'success',
                message: t.$t(response.body.msg),
              })
              if (!response.body.err) {
                this.$emit('close', 'reload')
              }
            },
            (err) => {
              console.log(err)
            }
          )
      }
    },
  },
}
</script>

<style></style>
